<template>
    <section v-if="!prop" class="loader-page-content">
      <PageLoader/>
    </section>
    <!-- Properties details page start -->
    <div v-else class="properties-details-page content-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <!-- Header -->
                    <div class="heading-properties clearfix sidebar-widget">
                        <div class="pull-left">
                            <h3>{{prop.propertyNameEs}}</h3>
                            <p v-if="prop.address">
                                <i class="fa fa-map-marker"></i>{{prop.address}}
                            </p>
                        </div>
                        <div class="pull-right">
                            <h3><span>${{format(prop.bestprice[0])}}</span></h3>
                            <h5>
                                En {{prop.toperation[0]}}
                            </h5>
                        </div>
                    </div>
                    <!-- Properties details section start -->
                    <div class="sidebar-widget mrg-btm-40">
                        <!-- Properties detail slider start -->
                        
						<div v-if="prop.images" class="row">
	
							<div class="col-md-12">
	
								<div class="property_slider">
	
									<div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
			
										<!-- Wrapper for slides -->
	
										<div class="carousel-inner" role="listbox">
	
											<!--<div class="item active">
	
												<img :src="photos[0]" alt="">
	
											</div>-->
	
											<div v-for="(image,i) in prop.images" :key="image.alt" :index="i" :class="['item', (i==0)?'active':'']" :style="'background-image:url('+image.largefile+'); '">
	
												<img >
                                                
                                                <div v-if="info&&infoId == prop.company.id&&info.logoClean" class="watermark" :style="'background-image:url('+info.logoClean+'); '"> </div>
											</div>
	
										</div>
			
										<!-- Controls -->
	
										<a id="leftProp" class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
	
											<div class="lef_btn"><i class="fa fa-arrow-left"></i></div>
	
											<span class="sr-only">Previous</span>
	
										</a>
	
										<a id="rightProp" class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
	
											<div class="right_btn"><i class="fa fa-arrow-right"></i></div>
	
											<span class="sr-only">Next</span>
	
										</a>
	
									</div>
									
									<b style="float:right; font-size:20px">										
										<a v-for="(photo,i) in prop.images" :key="photo.alt" :index="i" class="example-image-link" :href="photo.largefile" data-lightbox="example-set" :data-title="photo.alt">
											<small v-if="i==0"> Ver en grande <i class='fas fa-expand'></i> </small>
                                             <!--div v-if="info&&info.logoClean" class="watermark" :style="'background-image:url('+info.logoClean+'); '"> </div-->
										</a>
									</b>
								</div>
	
							</div>
	
						</div>	

                        <!-- Properties detail slider end -->

                        <!-- Properties prices start -->
                        <div class="properties-description mrg-btm-40 ">
                            <div class="main-title-2">
                                <h1><span>Precios</span></h1>
                            </div>
                            <div class="row">
                                <div v-for="(price,i) in prop.prices" :key="i" :index="i" >
                                    <div v-if="price" class="col-md-6 text-center">
                                        <h3><span>${{format(price)}}</span></h3>
                                        <h5>
                                            <b>
                                                Precio de {{prop.operation[i]}}
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Properties prices end -->

                        <!-- Properties description start -->
                        <div class="properties-description mrg-btm-40 ">
                            <div class="main-title-2">
                                <h1><span>Descripción</span></h1>
                            </div>
                            <div>
                                <h4>Español</h4>
                                <hr>
                                <p><pre>{{prop.descriptionEs}}</pre></p>
                                <br>
                            </div>
                            <div v-if="prop.descriptionEn">
                                <h4>English</h4>
                                <hr>
                                <p><pre>{{prop.descriptionEn}}</pre></p>
                            </div>
                        </div>
                        <!-- Properties description end -->

                        <!-- Properties condition start -->
                        <div v-if="prop.developmentServices || prop.services" class="properties-condition mrg-btm-40 ">
                            <div class="main-title-2">
                                <h1><span>Servicios</span></h1>
                            </div>
                            <div class="row">
                                <ul class="condition">
                                    <li v-for="(item,i) in prop.developmentServices" :key="i" :index="i" class="col-md-4">
                                        <i class="far fa-check-circle"></i> {{item}}
                                    </li>
                                    <li v-for="(item,i) in prop.services" :key="i" :index="i" class="col-md-4">
                                        <i class="far fa-check-circle"></i> {{item}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Properties condition end -->

                        <!-- Properties amenities start -->
                        <div v-if=" prop.interiorAmenities||prop.exteriorAmenities||prop.developmentAmenities||prop.otherAmenities" class="properties-amenities">
                            <div class="main-title-2">
                                <h1><span>Amenidades</span></h1>
                            </div>
                            
                            <div v-if="prop.interiorAmenities || prop.otherAmenities">
                                <h4>Interiores</h4>
                                <hr>
                                <div class="row">
                                    <ul class="amenities">
                                        <li v-for="(item,i) in prop.interiorAmenities" :key="i" :index="i"  class="col-md-4">
                                            <i class="far fa-check-circle"></i> {{item}}
                                        </li>
                                        <li v-for="(item,i) in prop.otherAmenities" :key="i" :index="i"  class="col-md-4">
                                            <i class="far fa-check-circle"></i> {{item}}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="prop.exteriorAmenities">
                                <h4>Exteriores</h4>
                                <hr>
                                <div class="row">
                                    <ul class="amenities">
                                        <li v-for="(item,i) in prop.exteriorAmenities" :key="i" :index="i"  class="col-md-4">
                                            <i class="far fa-check-circle"></i> {{item}}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="prop.developmentAmenities">
                                <h4>De Desarrollo</h4>
                                <hr>
                                <div class="row">
                                    <ul class="amenities">
                                        <li v-for="(item,i) in prop.developmentAmenities" :key="i" :index="i"  class="col-md-4">
                                            <i class="far fa-check-circle"></i> {{item}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- Properties amenities end -->
                    </div>
                    <!-- Properties details section end -->

                    <!-- Location start -->
                    <div v-if="prop.latitude|| prop.longitude" class="location sidebar-widget">
                        <div class="map">
                            <div class="main-title-2">
                                <h1><span>Ubicación</span></h1>
                            </div>
                            <GoogleMap :mapLat="prop.latitude" :mapLng="prop.longitude" />
                        </div>
                    </div>
                    <!-- Location end -->
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <!-- Sidebar start -->
                    <div class="sidebar right">

                        <!-- Agent widget start -->
                        <div class="sidebar-widget contact-form agent-widget">
                            <div class="main-title-2">
                                <h1><span>Solicitar</span> información</h1>
                            </div>
                            <ContactForm/>
                        </div>
                        <!-- Agent widget end -->

                        <!-- Helping center start -->
                        <div class="sidebar-widget helping-box clearfix">
                            <div class="main-title-2">
                                <h1><span>Principales</span> Características</h1>
                            </div>
                            <div class="helping-center">
                                <div class="icon"><i class="fa fa-hashtag"></i></div>
                                <h4>Folio</h4>
                                <span>{{prop.folio}}</span>
                            </div>
                            <div v-if="prop.bathrooms" class="helping-center">
                                <div class="icon"><i class="flaticon-holidays"></i></div>
                                <h4>Baños</h4>
                                <span>{{prop.bathrooms}}</span>
                            </div>
                            <div v-if="prop.bedrooms" class="helping-center">
                                <div class="icon"><i class="flaticon-bed"></i></div>
                                <h4>Recámaras</h4>
                                <span>{{prop.bedrooms}}</span>
                            </div>
                            <div v-if="prop.m2c || prop.mlot" class="helping-center">
                                <div class="icon"><i class="flaticon-square-layouting-with-black-square-in-east-area"></i></div>
                                <h4>M<sup>2</sup> de {{(prop.m2c)?'construcción':'terreno'}}</h4>
                                <span>{{(prop.m2c)?prop.m2c:prop.mlot}}</span>
                            </div>
                            <div v-if="prop.num_parking" class="helping-center">
                                <div class="icon"><i class="flaticon-transport"></i></div>
                                <h4>Cajones de estacionamiento</h4>
                                <span>{{prop.num_parking}}</span>
                            </div>
                        </div>
                        <!-- Helping center end -->

                        <!-- Documents start -->
                        <div class="social-media sidebar-widget clearfix">
                            <!-- Main Title 2 -->
                            <div class="main-title-2">
                                <h1><span>Contenido</span> disponible</h1>
                            </div>
                            <!-- Social list -->
                            <div class="tags-box">
                                <h4>Flyers</h4>
                                <hr>
                                <ul class="tags cc_cursor">
                                    <li>
                                        <a v-if="prop.flyerEs" :href="prop.flyerEs" target="_blank" >
                                            <i class="far fa-file-pdf"></i> Flyer Español                                           
                                        </a>
                                    </li>
                                    <li>
                                        <a v-if="prop.flyerEn" :href="prop.flyerEn" target="_blank">
                                            <i class="far fa-file-pdf"></i> Flyer English
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tags-box">
                                <h4>Banner</h4>
                                <hr>
                                <ul class="tags cc_cursor">                                    
                                    <li>
                                        <a v-if="prop.banner1" :href="prop.banner1" target="_blank" >
                                            <i class="far fa-file-image"></i> Banner 1 foto
                                        </a>
                                    </li>
                                    <li>
                                        <a v-if="prop.banner3" :href="prop.banner3" target="_blank" >
                                            <i class="far fa-file-image"></i> Banner 3 fotos
                                        </a>
                                    </li>
                                    <li>
                                        <a v-if="prop.banner4" :href="prop.banner4" target="_blank" >
                                            <i class="far fa-file-image"></i> Banner 4 fotos
                                        </a>
                                    </li>
                                    <li>
                                        <a v-if="prop.banner6" :href="prop.banner6" target="_blank" >
                                            <i class="far fa-file-image"></i> Banner 6 fotos
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Documents end -->

                        <!-- Social media start -->
                        <div class="social-media sidebar-widget clearfix">
                            <!-- Main Title 2 -->
                            <div class="main-title-2">
                                <h1><span>Comparte</span> en redes</h1>
                            </div>
                            <!-- Social list -->
                            <ul class="social-list">
                                <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a :href="`https://twitter.com/home?status=${url}`" target="_blank" class="twitter"><i class="fab fa-twitter"></i></a></li>
                                <li><a :href="`https://api.whatsapp.com/send?text=Buen día estoy interesado en esta propiedad:${url}&phone=${prop.agent.cellphone}`" target="_blank" class="whatsapp"><i class="fab fa-whatsapp"></i></a></li>
                                <li><a :href="`https://pinterest.com/pin/create/button/?url=${url}&amp;media=${prop.images[0].smallfile}&amp;description=${prop.propertyNameEs}`" target="_blank" class="google"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <!-- Social media end -->

                        <!-- Inside properties start  -->
                        <div v-if="prop.video" class="inside-properties sidebar-widget">
                            <!-- Main Title 2 -->
                            <div class="main-title-2">
                                <h1><span>Video</span> de la propiedad</h1>
                            </div>
                            <iframe :src="prop.video" allowfullscreen=""></iframe>
                        </div>
                        <!-- Inside properties end -->

                        <!-- Helping center start -->
                        <!--div class="sidebar-widget helping-box clearfix">
                            <div class="main-title-2">
                                <h1><span>Información</span> de compañia</h1>
                            </div>
                            <div class="row justify-content-center text-center">
                                <h4 v-if="prop.company.name" class="col-xs-12">
                                    {{prop.company.name}}
                                </h4>
                            </div>
                            <hr>
                            <div v-if="prop.company.address" class="helping-center">
                                <div class="icon"><i class="fas fa-map-marker-alt"></i></div>
                                <h4>Dirección</h4>
                                <span>{{prop.company.address}}</span>
                            </div>
                            <div v-if="prop.company.phone" class="helping-center">
                                <div class="icon"><i class="fa fa-phone"></i></div>
                                <h4>Teléfono</h4>
                                <p>{{prop.company.phone}}</p>
                            </div>
                            <div v-if="prop.company.mail" class="helping-center">
                                <div class="icon"><i class="fa fa-envelope"></i></div>
                                <h4>Correo Electrónico</h4>
                                <p><a :href="`mailto:${prop.company.mail}`">{{prop.company.mail}}</a> </p>
                            </div>
                        </div-->
                        <!-- Helping center end -->
                    </div>
                    <!-- Sidebar end -->
                </div>
            </div>
        </div>
    </div>
    <!-- Properties details page end -->
</template>

<script>
import {mapGetters} from "vuex";
import GoogleMap from "@/components/properties/GoogleMap";
import ContactForm from "@/components/properties/ContactForm";
import PageLoader from '@/components/layout/PageLoader';
export default {
    components: {
        GoogleMap,
        ContactForm,
        PageLoader,
    },
    data() {
        return {
            folio: "",
            agentId: 0,
            url: "",
            amenities: [],
        };
    },
    computed: {
        ...mapGetters({
            prop: '_getProperty',
            info:'_getInfoCompany',
            infoId:'_getCompanyId'
        })
    },
    created() {

        this.folio = this.$route.params.folio;

        this.agentId = this.$route.params.agentId;
        
        let data = {
        folio: this.folio,
        agent: this.agentId,
        };
        this.$store.dispatch("getProperty", data);
        this.url = this.$store.state.URL_SITE + this.$route.path;

        //Send Data to Access
        if (process.env.NODE_ENV === "production") {
        this.$getLocation().then((coordinates) => {
            let accessData = {
            lat: coordinates.lat,
            lng: coordinates.lng,
            url: this.url,
            propertyId: this.folio,
            };

            this.$store.dispatch("setPropertyClick", accessData);
        });
        }
    },
    methods:{
        format(val) {
            let value=val
            //Formateo de decimales
            let decimalPrice = value.lastIndexOf(".");
            //Extaracción de moneda
            let currentPriceM = value.indexOf("MXN");
            let currentPriceU = value.indexOf("USD");

            // Precio formateado value.substr(currentPrice)
            let formatPrice=value.substr(0,decimalPrice)+" ";
            if(currentPriceM!='-1'){
                formatPrice+= value.substr(currentPriceM)
            }else{
                formatPrice+= value.substr(currentPriceU)
            }
            return formatPrice.slice(1);
        }
    }
}
</script>

<style>
pre {

    white-space:pre-line;

    height: auto;

    overflow: hidden;

    background: transparent;

    border-color: transparent;

    color: inherit;

    font-family: inherit;

    font-size: inherit;

    text-align: justify;

}
.logo-copmany-detail{
    height: 80px;
}
.captcha-info{
    color: gray;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.carousel-control{
		color: rgb(250, 0, 44);
	}
.carousel-inner{
		height: auto;
	}
	.carousel-inner .item{
		background-position: center;
		background-repeat: no-repeat; 
		background-size:contain; 
		height:auto;
	}
	@media (max-width: 767px){			
		.property_slider .carousel-inner > .item > img{
			height: 300px !important;
		}
	}
	.property_slider .carousel-inner > .item > img{
		border:none;
		background: transparent;

	}
	.property_slider .carousel-inner .item::before{
		border:none;
    }
    .property_slider{
	    margin-bottom: 30px
    }
    .property_slider .carousel-inner .item::before {
        background: transparent
    }
    .property_slider .carousel-inner > .item > img, 
    .property_slider .carousel-inner > .item > a > img {
        height: 580px;
        width:100%;
    }
    .lef_btn, .right_btn {
        text-transform: uppercase;
        border: 2px solid;
        padding: 5px 15px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        top: 45%;
        border-radius: 5px;
    }
    .lef_btn {
        left: 20px;
    }
    .right_btn {
        right: 20px;
    }

    
.watermark{
    height: 580px;
    margin-top: -580px;
    background-size: 30%;
}

.watermark{
  opacity: .6;
}
</style>