<template>
    <section v-if="!properties" class="loader-page-content">
      <PageLoader/>
    </section>
  <div v-else class="properties-section-body content-area">
    <div class="container">
        <div class="row">
            <NotFound v-if="error==204" code="204"/>
            <div v-else class="col-lg-12 col-md-12 col-xs-12">
                <div class="clearfix"></div>

                <!-- Property grid start -->
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 wow fadeInUp delay-03s property-item" v-for="(item,i) in properties" :key="item.folio" :name="item.folio" :index="i">
                        <Property :item="item"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Property from '@/components/properties/PropertyCard'
import PageLoader from '@/components/layout/PageLoader';
import NotFound from '@/components/layout/NotFound.vue';
export default {
    components:{
        Property,
        PageLoader,
        NotFound
    },
    data() {
        return {
            codeShareProperties: 0,
            idCompany:0
        };
    },
    computed: {
        ...mapGetters({
            properties: "_getProperties",
            error: "_getError",
        }),
    },
    created() {        
    
        let data={
          idCompany:this.$route.params.idCompany,
          codeShareProperties:this.$route.params.codeShareProperties
      }
      this.$store.dispatch("getProperties", data);
    },
}
</script>
<style>
.property-item{
    min-height: 580px
}
</style>