<template>
  <main>
    <div class="menu" @click="goHome()">
      <i class="hamburger fas fa-arrow-left"></i>
    </div>
  </main>
</template>

<script>
export default {
    methods:{
        goHome(){
            let codeShareProperties = this.$store.state.CODE_PROPERTIES;
            let idCompany = this.$store.state.ID_COMPANY;
            
            if(codeShareProperties){
                this.$router.push({
                    name:'Properties',
                    params:{
                      codeShareProperties,
                      idCompany
                    }
                })
            }else{
                this.$router.go(-1)
            }
        }
    }
};
</script>

<style>


.menu {
-webkit-tap-highlight-color: transparent;
    background: #ffffffeb;
    border-radius: 100%;
    box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    height: 150px;
    left: -70px;
    position: absolute;
    top: -70px;
    width: 150px;
    z-index: 9;
    position: fixed;
}
.hamburger {
  cursor: pointer;
    height: 55px;
    left: 57%;
    position: relative;
    top: 53%;
    width: 55px;
    font-size: 40px;
    color: #fb4c6b;
}
.menu:hover .hamburger{
    animation-name: example;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@keyframes example {
  0%   {left:57%; top:53%;}
  25%  {left:60%; top:53%;}
  50%  {left:57%; top:53%;}
  75%  {left:60%; top:53%;}
}
</style>
