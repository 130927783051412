import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /*****************************************************
         * General Info
         *****************************************************/
        API_URL: "https://www.crm.immo360.com.mx/api/index.php",
        CONTACT_URL: "https://www.immosystem.com.mx/api/mailWeb/mailerContactExample.php",
        API_PARAMS: {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: {}
        },
        NEWS_VISITS: 0,
        NEWS_CONTACTS: 0,
        USER_IP: null,
        CAPTCHA_KEY: "6Lf_1ZMUAAAAAC1iY_FzMJpgod5bIVl_2YYHLT9g",
        ID_COMPANY: "",
        URL_SITE: "https://proplist.immo360.com.mx",
        ACTUAL_PAGE: "",
        CODE_PROPERTIES: null,
        INFO_COMPANY: null,
        INFO_AGENT: null,
        ERROR_CODE: 0,
        MATADATA: {
            company: 0,
            detail: 0
        },
        PROPS_PER_PAGE: 9,
        AGENTS_PER_PAGE: 8,

        /*****************************************************
         * Developments Info
         *****************************************************/
        developmentsList: null,
        developmentDetail: 0,
        /*****************************************************
         * Properties Info
         *****************************************************/
        homeCarousel: [],
        homeList: null,
        propertiesList: null,
        propertiesByAgent: null,
        propertiesDetail: 0, //Cache properties
        propertyDetail: 0,
        propertiesSearch: null,
        propertyAccess: null
    },
    mutations: {
        /*****************************************************
         * General Mutations
         *****************************************************/

        setNewVisit(state) {
            state.NEWS_VISITS++;
        },
        setNewContact(state) {
            state.NEWS_CONTACTS++;
        },

        setCompanyId(state, id) {
            if (!state.ID_COMPANY) {
                state.ID_COMPANY = id
            }
        },
        setInfoCompany(state, info) {
            if (!state.INFO_COMPANY) {
                info.logoClean = (info.logoClean != 0) ? `https://crm.immo360.com.mx/${info.logoClean}` : `https://crm.immo360.com.mx/${info.logo}`;
                state.INFO_COMPANY = info;
            }
        },
        setInfoAgent(state, agent) {
            if (!state.INFO_AGENT) {
                state.INFO_AGENT = agent;
            }
        },

        setCodeProperties(state, info) {
            state.CODE_PROPERTIES = info.codeShareProperties
            state.ID_COMPANY = info.idCompany
        },

        setUserIp(state, userIp) {
            state.USER_IP = userIp.ip;
        },

        setError(state, code) {
            state.ERROR_CODE = 0;
            state.ERROR_CODE = code;
        },


        /*****************************************************
         * Properties Mutations
         *****************************************************/
        setProperties(state, properties) {
            state.propertiesList = properties;
        },
        setPropertiesByAgent(state, properties) {
            state.propertiesByAgent = properties;
        },
        setProperty(state, property) {
            state.propertyDetail = property;
        },
        resetProperty(state, property) {
            state.propertyDetail = property;
        },

        /*****************************************************
         * Developments Mutations
         *****************************************************/
        setDevelopments(state, developments) {
            state.developmentsList = developments;
        },
        setDevelopment(state, development) {
            state.developmentDetail = development;
        },
        resetDevelopment(state, development) {
            state.developmentDetail = development;
        }
    },
    actions: {
        /*****************************************************
         * General Actions
         *****************************************************/

        setNewVisit({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=countVisits&folio=${state.ID_COMPANY}&unic=1`;
            config.body = data;
            if (process.env.NODE_ENV === 'production') {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setNewVisit');
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        setConcurrentVisit({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=countVisits&folio=${state.ID_COMPANY}&concurrent=1`;
            config.body = data;
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setNewVisit');
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })
        },
        setPropertyClick({ commit, state }, propertyData) {
            let config = state.API_PARAMS;
            let data = `m=viewsProperty&origincode=7952&folio=${state.ID_COMPANY}`;
            for (let param in propertyData) {
                data += `&${param}=${propertyData[param]}`;
            }

            config.body = data;
            if (process.env.NODE_ENV === 'production') {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setNewVisit');
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        setNewContact({ commit, state }, contactInfo) {
            let config = state.API_PARAMS;
            let data = `m=contact&folio=${state.ID_COMPANY}`;
            for (let param in contactInfo) {
                data += `&${param}=${contactInfo[param]}`;
            }

            config.body = data;
            if (process.env.NODE_ENV === 'production') {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setNewContact');
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        getInfoCompany({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=footer&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.INFO_COMPANY) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setInfoCompany', res.data[0]);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

            fetch('https://api.ipify.org?format=json')
                .then(res => res.json())
                .then(res => {
                    commit('setUserIp', res);
                })
        },

        /*****************************************************
         * Home Actions
         *****************************************************/
        getHomeSlide({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=slider&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (state.homeCarousel.length == 0) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setHomeSlide', res.data);
                                //commit('configureImages','homeSlide');  COMMENTED FOR ERROR WAIT FOR FUTURE IMPLEMENTS
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

        },
        getHomeProperties({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=properties&limit=6&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.homeList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setHomeProperties', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },

        /*****************************************************
         * Properties Actions
         *****************************************************/
        getProperties({ commit, state, dispatch }, info) {
            let config = state.API_PARAMS;
            let data
            let company = info.idCompany;
            let codeShareProperties = info.codeShareProperties
            if (codeShareProperties != 0) {
                data = `m=properties&shareProps=${codeShareProperties}`;
                commit('setCodeProperties', info);
            } else {
                data = `m=properties&folio=${state.ID_COMPANY}`;
            }

            config.body = data;
            if (!state.propertiesList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setProperties', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setProperties', res.data);
                                commit('setError', 204);
                            }

                            if (res.user) {
                                commit('setInfoAgent', res.user[0])
                            }
                            commit('setCompanyId', company[0].id)
                        } else {
                            commit('setError', res.status);
                        }
                        dispatch('getInfoCompany');
                    })

            }

        },

        getPropertiesByAgent({ commit, state }, agent) {
            let config = state.API_PARAMS;
            let data = `m=findProperty&folio=${state.ID_COMPANY}&user=${agent}`;

            config.body = data;

            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setPropertiesByAgent', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })


        },

        getProperty({ commit, state, dispatch }, info) {
            commit('resetProperty', 0);
            let config = state.API_PARAMS;
            let data;
            if (info.agent != 0) {
                data = `m=property&folio=${info.folio}&user=${info.agent}`;
            } else {
                data = `m=property&folio=${info.folio}`;
            }
            config.body = data;
            //if(!state.propertyDetail){
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setProperty', res.data);
                        commit('setError', res.status);

                        if (res.data.agent) {
                            commit('setInfoAgent', res.data.agent)
                            commit('setCompanyId', res.data.agent.companyid)
                        }
                    } else {
                        commit('setError', res.status);
                    }
                    dispatch('getInfoCompany');
                })
                //}
        },
        /*****************************************************
         * Developments Actions
         *****************************************************/
        getDevelopments({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=developments&folio=${state.ID_COMPANY}`;

            config.body = data;
            if (!state.developmentsList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setDevelopments', res.data);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

        },
        getDevelopment({ commit, state }, info) {
            commit('resetDevelopment', 0);
            let config = state.API_PARAMS;
            let data = `m=development&folio=${info.folio}`;
            config.body = data;
            //if(!state.propertyDetail){
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setDevelopment', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })
                //}
        },
    },
    getters: {
        _getInfoCompany(state) {
            return state.INFO_COMPANY;
        },
        _getCompanyId(state) {
            return state.ID_COMPANY;
        },
        _getInfoAgent(state) {
            return state.INFO_AGENT;
        },
        _getHomeSlide(state) {
            return state.homeCarousel;
        },
        _getHomeProperties(state) {
            return state.homeList;
        },
        _getProperties(state) {
            return state.propertiesList;
        },
        _getPropertiesByAgent(state) {
            return state.propertiesByAgent;
        },
        _getDevelopments(state) {
            return state.developmentsList;
        },
        _getProperty(state) {
            return state.propertyDetail
        },
        _getDevelopment(state) {
            return state.developmentDetail
        },
        _getError(state) {
            return state.ERROR_CODE
        }
    }
})