<template>
    <!-- Property start -->
    <div class="property">
        <!-- Property img -->
        <a  @click="goToProperty()"  class="property-img">
            <div v-if="item.luxury" class="property-tag button alt featured">Destacada</div>
            <div class="property-tag button alt folio-tag">#{{item.folio}}</div>
            <div class="property-tag button sale">{{item.bestOperation}}</div>
            <div class="property-price">${{format(item.bestPrice)}}</div>
            <img :src="item.smallphoto" :alt="item.alt" :title="item.namePropertyEs+'-IMAGE'" class="img-responsive">
            
            
            
            <div v-if="info&&infoId == item.companyid&&info.logoClean" class="watermark" :style="'background-image:url('+info.logoClean+'); '"> </div>
        </a>
        
        <!-- Property content -->
        <div class="property-content">
            <!-- title -->
            <h1 class="title">
                <a  @click="goToProperty()" >{{item.namePropertyEs}}</a>
            </h1>
            <!-- Property address -->
            <h3 v-if="item.address&item.address!='0'" class="property-address">
                <a  @click="goToProperty()" >
                    <i class="fa fa-map-marker"></i> {{item.address}}
                </a>
            </h3>
            <!-- Facilities List -->
            <ul class="facilities-list clearfix">
                <li v-if="item.m2c&&item.m2c!='0'">
                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                    <span >{{item.m2c}} m<sup>2</sup></span>
                </li>
                <li v-else-if="item.mlot&&item.mlot!='0'">
                    <i class="flaticon-square-layouting-with-black-square-in-east-area"></i>
                    <span >{{item.mlot}} m<sup>2</sup></span>
                </li>
                <li v-if="item.parkingTot&&item.parkingTot!='0'">
                    <i class="flaticon-vehicle"></i>
                    <span>{{item.parkingTot}} Cajones</span>
                </li>
                <li v-if="item.bedrooms&&item.bedrooms!='0'">
                    <i class="flaticon-bed"></i>
                    <span> {{item.bedrooms}} Recámaras</span>
                </li>
                <li v-if="item.bathrooms&&item.bathrooms!='0'">
                    <i class="flaticon-holidays"></i>
                    <span> {{item.bathrooms}} Baños</span>
                </li>
            </ul>
        </div>
    </div>
    <!-- Property end -->
</template>

<script>
import { mapGetters } from "vuex";
import services from '@/store/_services'
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    },
    computed:{
        ...mapGetters({
            agent: "_getInfoAgent",
            info:'_getInfoCompany',
            infoId:'_getCompanyId'
        }),
        priceInfo(){
            //Formateo de decimales
            let decimalPrice = this.item.bestPrice.lastIndexOf(".");
            //Extaracción de moneda
            let currentPriceM = this.item.bestPrice.indexOf("MXN");
            let currentPriceU = this.item.bestPrice.indexOf("USD");
            
            // Precio formateado value.substr(currentPrice)
            let formatPrice=this.mainPrice.substr(0,decimalPrice)+" ";
            if(currentPriceM!='-1'){
                formatPrice+= this.mainPrice.substr(currentPriceM)
            }else{
                formatPrice+= this.mainPrice.substr(currentPriceU)
            }
            return formatPrice;
        }
    },
    data(){
        return{
            mainPrice:0,
            secondPrice:0,
            mainOperation:0,
            secondOperation:0,
        }
    },
    methods:{
        goToProperty(){
            let namePropFilt=services.normalize(this.item.namePropertyEs)
            let folio = this.item.folio;
            let agentId=this.agent.agentId
            this.$router.push({
                name: 'Property',
                params: {
                    agentId,
                    folio,
                    namePropFilt
                }
            });
        },
        format(val) {
            let value=val
            //Formateo de decimales
            let decimalPrice = value.lastIndexOf(".");
            //Extaracción de moneda
            let currentPriceM = value.indexOf("MXN");
            let currentPriceU = value.indexOf("USD");

            // Precio formateado value.substr(currentPrice)
            let formatPrice=value.substr(0,decimalPrice)+" ";
            if(currentPriceM!='-1'){
                formatPrice+= value.substr(currentPriceM)
            }else{
                formatPrice+= value.substr(currentPriceU)
            }
            return formatPrice.slice(1);
        }
    }
}
</script>

<style scoped>
.img-responsive{
    height: 250px;
}

.property-img .watermark{
    margin-top: -250px;
    height: 250px;
    width: 100%;
    background-size: 30%;
}

.watermark{
  opacity: .6;
}

</style>