<template>
    <!-- Main header start -->
    <header class="main-header">
        <div class="container">
            <nav class="navbar navbar-default">
                <div v-if="info" class="navbar-header">
                    <!--button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#app-navigation" aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button-->
                    <img v-if="info.logo" :src="info.logo" :alt="info.alt">
                    <h3 v-else>{{info.companyName}}</h3>
                    
                </div>
                <!-- Collect the nav links, forms, and other content for toggling -->
                <!--div class="navbar-collapse collapse" role="navigation" aria-expanded="true" id="app-navigation">
                    <ul class="nav navbar-nav">
                        <li class="dropdown ">
                            <router-link to="/">Inicio</router-link>
                        </li>
                        <li class="dropdown ">
                            <a>
                                Ir a sitio web
                            </a>
                        </li>
                    </ul>
                </div-->

                <!-- /.navbar-collapse -->
                <!-- /.container -->
            </nav>
        </div>
    </header>
    <!-- Main header end -->
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    }
}
</script>
<style >
.navbar-header img{
    max-width: 200px;
}
</style>