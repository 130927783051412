<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <!-- Error404 content start -->
                <NotFound code="404"/>
                <!-- Error404 content end -->
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from '@/components/layout/NotFound.vue';
export default {
  components:{
    NotFound
  }
}
</script>