<template>
    <div v-if="agent" class="container agent-banner">
        <div class="agent-box-big clearfix">
            <div v-if="agent.image!=0" class="col-lg-4 col-md-4 col-sm-4 agent-box-theme agent-theme">
                <img :src="agent.image" alt="agent-1" class="img-responsive">
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 agent-content">
                <h1 class="title">
                    <a href="#">{{agent.name}}</a>
                </h1>
                <hr>
                <!-- Address list -->
                <ul class="address-list">
                    <li>
                        <span>
                            <i class="fa fa-envelope"></i>Email:
                        </span>
                        {{agent.email}}
                    </li>
                    <li>
                        <span>
                            <i class="fa fa-mobile"></i>Celular:
                        </span>
                        {{agent.cellphone}}
                    </li>
                </ul>
                <!-- social list -->
                <ul class="social-list clearfix">
                    <li><a class="twitter" v-if="agent.instagram && agent.instagram!='0'" :href="agent.instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a class="google" v-if="agent.pinterest && agent.pinterest!='0'" :href="agent.pinterest" target="_blank"><i class="fab fa-pinterest-p"></i></a></li>
                    <li><a class="linkedin" v-if="agent.linkedin && agent.linkedin!='0'" :href="agent.linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a class="facebook" v-if="agent.facebook && agent.facebook!='0'" :href="agent.facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            agent:'_getInfoAgent'
        }),
    }
}
</script>

<style>
.agent-banner{
    margin-top: 50px;
}
</style>