<template>
    <form id="agent_form" v-on:submit.prevent="sendMail">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group enter-email">
                    <input v-model="propertyForm.name.value" :disabled="sent||sending" type="text" name="name" class="input-text" placeholder="Nombre completo *">
                    <div v-show="!propertyForm.name.status" class="invalid-feedback">Este campo es requerido.</div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group enter-email">
                    <input v-model.trim="propertyForm.email.value" :disabled="sent||sending" type="email" name="email" class="input-text" placeholder="Correo Electrónico *">
                    <div v-if="!propertyForm.email.status" class="invalid-feedback">Este campo es requerido y debe tener el formato correcto.</div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group number">
                    <vue-tel-input 
                        name="phone"
                        placeholder="Teléfono *"
                        class="input-text" 
                        v-model.trim="propertyForm.phone.value"
                        :disabled="sent||sending"
                        v-bind="bindTelProps"
                    />
                    <div v-if="!propertyForm.phone.status" class="invalid-feedback">Este campo es requerido.</div>
                    <!--input type="text" name="phone" class="input-text"  placeholder="Teléfono *"-->
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group message">
                    <textarea v-model="propertyForm.message.value" :disabled="sent||sending" class="input-text" name="message" placeholder="Mensaje *"></textarea>
                    <div v-if="!propertyForm.message.status" class="invalid-feedback">Este campo es requerido.</div>
                </div>
            </div>
            <VueRecaptcha 
                :sitekey="$store.state.CAPTCHA_KEY"
                ref = "invisibleRecaptcha"
                @verify = "onCaptchaVerified"
                @expired = "onCaptchaExpired"
                size="invisible" 
            />
            <div class="col-lg-12">
                <button v-if="!sending" :disabled="sent" type="submit" class="button-md button-theme btn-block">Enviar</button>
                <div class="captcha-info"> 
                    Este sitio está protegido por reCAPTCHA y Google
                    <a href="https://policies.google.com/privacy"> Política de privacidad </a> y
                    Se aplican <a href="https://policies.google.com/terms"> Términos de servicio</a>.
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { mapState,mapGetters } from 'vuex';
import services from '@/store/_services';
import VueRecaptcha from 'vue-recaptcha';
export default {
    components:{
        VueRecaptcha
    },
    computed:{
        ...mapState({
            URL: state => state.CONTACT_URL,
            PARAMS: state => state.API_PARAMS,
            SITE: state=>state.URL_SITE
        }),
        ...mapGetters({
            companyInfo:'_getInfoCompany',
            propertyInfo:"_getProperty"
        })
    },
     data(){
        return{
            propertyForm:{
                name:{
                    value:'',
                    status:Boolean
                },
                phone:{
                    value:'',
                    status:Boolean
                },
                email:{
                    value:'',
                    status:Boolean
                },
                message:{
                    value:'',
                    status:Boolean
                },
            },
            sending:false,
            sent:false,
            folio:"",
            url:"",
            agentEmail:"",
            managerEmail:"",
            bindTelProps:{
                id:"phoneContact",
                mode: "international",
                defaultCountry: "MX",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: true,
                placeholder: "Teléfono",
                required: false,
                enabledCountryCode: true,
                enabledFlags: true,
                preferredCountries: ["MX", "BR","VE"],
                onlyCountries: [],
                ignoredCountries: [],
                autocomplete: "off",
                name: "telephone",
                maxLen: 18,
                inputClasses: "form-control py-0",
                dropdownOptions: {
                    disabledDialCode: false
                },
                inputOptions: {
                    showDialCode: false
                }
            }
        }
    },
    methods:{
        sendMail(){
            //Activate Loader
            this.sending=true;
            
            //Validate empty form
            for(let x in this.propertyForm){
                if(this.propertyForm[x].value==''){
                    this.propertyForm[x].status=false;
                    this.sending=false;
                    return false;
                }
            }

            //Validate email
            if(!this.validateEmail(this.propertyForm.email.value)){
                this.$swal.fire({
                    icon: 'error',                    
                    text: 'El formato del correo electrónico ingresado no es válido.',
                    showConfirmButton: false,
                    showCloseButton: true,
                });
                this.propertyForm.email.status=false;
                this.sending=false;
                return false;
            }else{
                this.propertyForm.email.status=true;
            }
            console.log('verificando')
            //Validate with captcha
            this.$refs.invisibleRecaptcha.execute();
           
        },
        validateEmail(email){
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        },
        onCaptchaVerified() {
            console.log('verificadop')
            // / &//companyEmail=${self.companyInfo.reserveemail} &agentEmail=${self.agentEmail}
            let self=this;
            let config=self.PARAMS;
            let data = `name=${ self.propertyForm.name.value}&email=${ self.propertyForm.email.value}&message=${ self.propertyForm.message.value}&phone=${ self.propertyForm.phone.value}&companyName=${ self.companyInfo.name}&companyEmail=${self.companyInfo.mail}&companyAddress=${ self.companyInfo.address}&companyPhone=${ self.companyInfo.phone}&companyLogo=${ self.companyInfo.logo}&urlSite=${self.SITE}&folio=${self.folio}&urlProp=${self.url}&agentEmail=${self.agentEmail}&origin=property&special=${self.companyInfo.id}`;
            
            config.body=data;
            /*self.$swal.fire({
                        icon: 'success',                    
                        title: '¡Envio completo!',
                        text: `Gracias por contactarnos :)`,
                        showConfirmButton: false,
                        showCloseButton: true,
                    });*/
            //Send Mail
            services.sendMail(self.URL,config)
            .then((res)=>{
                if(res==200){
                    self.$swal.fire({
                        icon: 'success',                    
                        title: '¡Envio completo!',
                        text: `Gracias por contactarnos :)`,
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                    self.sending=false;
                    self.sent=true;

                    self.$getLocation()
                    .then(coordinates=>{
                        let contactInfo={
                            fullname:self.propertyForm.name.value,
                            email:self.propertyForm.email.value,
                            phone:self.propertyForm.phone.value,
                            message:self.propertyForm.message.value,
                            propertyid:self.folio,
                            soldagentid:self.propertyInfo.agent.agentId,
                            latitude:coordinates.lat,
                            longitude:coordinates.lng,
                            contacttype:2
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                    .catch(()=>{
                        let contactInfo={
                            fullname:self.propertyForm.name.value,
                            email:self.propertyForm.email.value,
                            phone:self.propertyForm.phone.value,
                            message:self.propertyForm.message.value,
                            propertyid:self.folio,
                            soldagentid:self.propertyInfo.agent.agentId,
                            latitude:0,
                            longitude:0,
                            contacttype:2
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                }else{
                    self.$swal.fire({
                        icon: "error",
                        text: "No se pudo completar el envio del formulario.",
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            })
            .catch(()=>{
                self.$swal.fire({
                    icon: "error",
                    text: "No se pudo completar el envio del formulario.",
                    showConfirmButton: false,
                    showCloseButton: true
                });
            });
            return;
        },
        onCaptchaExpired() {
            console.log('verificadop')
            this.$swal.fire({
                icon: "error",
                text: "No se pudo completar el envio del formulario.",
                showConfirmButton: false,
                showCloseButton: true
            });
            this.$refs.invisibleRecaptcha.reset();
        }
    },
    created(){
        this.url=this.SITE+this.$route.fullPath;
        this.folio=this.propertyInfo.folio;
        this.agentEmail=this.propertyInfo.agent.email;
        this.managerEmail=this.propertyInfo.manager.email;
    }
}
</script>

<style>
.vti__input{
    margin-top: -10px;
    box-shadow: none;
}
.vue-tel-input .form-control:focus{
    border-color:transparent;
    box-shadow: none;
}
.invalid-feedback{
    font-size: 12px;
    color: red;
}
</style>